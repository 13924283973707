<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">후쿠오카 지역의 매력적인 관광지와 인기시설을 대상으로 한 가성비 있는 티켓입니다. 유효기간 내에 대상 시설 중에서 원하는 시설을 2곳 선택하여 이용하실 수 있습니다. E-티켓에 기재된 QR코드를 제시하시면 간편하게 입장할 수 있습니다.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">사용방법</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● 사용방법 : 예약 후 메일로 1장의 PDF를 수령한 후, 이용기간 중 해당 시설에 방문하여 QR코드를 제시한 뒤 교환해 주세요. <br>
          ● 각 시설은 1회 한정으로 이용 가능하며, 동일 시설에 재입장하실 수 없습니다. ● 이용 개시 가능일 : 구입일로부터 <span>90일간</span> <br>
          예시 : <span>7/1</span> 구입 ⇒ <span>이용 가능 기간 : 7/1~9/28</span> <br>
          ● 해당 패스는 첫 번째 시설 이용부터 <span>7일간</span> 유효합니다. <br>
          예시 : 첫 번째 시설 이용일 <span>7/1</span> ⇒ <span>유효기간 7/1~7/7</span> <br>
          ● 이용 가능 시설 : 유효 기간(7일) 내 <span>2개까지 </span><br>
          ● 1회 주문당 여러 개의 패스를 구입하는 경우, 모든 패스의 유효 기간은 동일하므로 주의하시기 바랍니다. (<span>이용 개시부터 7일간</span>) <br>
          ● 유효 기간은 이용 개시부터 7일간이지만, 구입일로부터 <span>90일</span> 이후에는 이용하실 수 없으므로 주의하시기 바랍니다. <br>
          예시 : 구입일이 <span>7/1</span>인 경우, <span>7/1~9/28</span>에만 이용 가능합니다. <span>9/27</span>부터 이용 개시하셔도 <span>9/28</span>까지만 이용하실 수 있습니다. <br>
          ● 미사용 시설은 환불되지 않습니다. <br>
          ● 각 시설의 주의사항, 영업시간, 정기휴일 등은 다음 링크에서 확인해 주세요. 
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='주의사항'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='티켓교환장소' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='공식 사이트' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hffukuoka/kr/havefun_title_kr.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hffukuoka/CasualDining.png'),
          title: ['캐주얼 다이닝 이자카야 유키하나'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: [
                '〒810-0003 후쿠오카시 츄오구 하루요시 3-11-19 파노라마 스퀘어 하카타 1층'],noColor:true }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [
                { cid: 1, text: ['https://www.instagram.com/yukihana_fuk/'] },
                ]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['・매장 체류 가능시간 : 약3시간. (주말/휴일 전날에만 제한있음)'] },
                { cid: 2, text: ['・영업시간은 사전에 공식사이트에서 확인해 주세요.'] },
                { cid: 3, text: ['・단기체류자격의 방일외국인에 한해 이용 가능합니다.'] },
                { cid: 4, text: ['・1명당 2천엔이상 결제하는 경우, 1천엔 쿠폰을 사용할 수 있습니다.'] },
                { cid: 5, text: ['・만석으로 이용하지 못할 가능성이 있습니다. 사전에 양해 부탁드립니다.'] },
                ]
            }
          ]
        },
        // 
        // {
        //   id: 2,
        //   img: require('@/assets/images/hffukuoka/KoreanstSeet.png'),
        //   title: ['한국 포장마차 달밤'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '티켓교환장소',
        //       content: [{ cid: 1, text: ['후쿠오카시 하카타구 시모카와바타쵸 1-331-2F'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '공식 사이트',
        //       content: [{ cid: 1, text: ['https://darubamu.owst.jp/ko/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '주의사항',
        //       isDecimal: false, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['・매장 체류 가능시간 : 약2시간30분.'] },
        //         { cid: 2, text: ['・영업시간은 사전에 공식사이트를 통해 확인해 주세요.'] },
        //         { cid: 3, text: ['・단기체류자격의 방일외국인에 한해 이용 가능합니다.'] },
        //         { cid: 4, text: ['・1명당 2천엔이상 결제하는 경우, 1천엔 쿠폰을 사용할 수 있습니다.'] },
        //         { cid: 5, text: ['・만석으로 이용하지 못할 가능성이 있습니다. 사전에 양해 부탁드립니다.'] },
        //       ]
        //     }
        //   ]
        // },
        // 
        {
          id: 3,
          img: require('@/assets/images/hffukuoka/HaruyoshiBar.png'),
          title: ['하루요시 Bar Rio'],
          message: [
            {
              msgId: 1,
              title: '티켓교환장소',
              content: [{ cid: 1, text: ['후쿠오카시 츄오구 하루요시 2-4-11 Riviere Champ 104'] }]
            },
            {
              msgId: 2,
              title: '공식 사이트',
              content: [{ cid: 1, text: ['https://www.instagram.com/haruyoshi_bar_rio/?igshid=YmMyMTA2M2Y%3D'] }]
            },
            {
              msgId: 3,
              title: '주의사항',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['・매장 체류 가능시간 : 2시간.'] },
                { cid: 2, text: ['・영업시간은 사전에 공식사이트를 통해 확인해 주세요.'] },
                { cid: 3, text: ['・단기체류자격의 방일외국인에 한해 이용 가능합니다.'] },
                { cid: 4, text: ['・1명당 2천엔이상 결제하는 경우에 사용 가능합니다.'] },
                { cid: 5, text: ['・만석으로 이용하지 못할 가능성이 있습니다. 사전에 양해 부탁드립니다.'] },
              ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFFukuoka&l=kr')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-fukuoka-1-week-free-pass/kr', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>